import { UserData } from '../contexts/UserContext';
import { getRequiredUserAttributes } from './UserAttributes';
import { AccountRepository } from '../api/AccountRepository';
import { createAuthorizedClient } from '../api/http/createAuthorizedClient';

export const reloadUserData = async (
  previousUserData: UserData,
  setUserData: (updatedUserData: UserData) => void
) => {
  const httpClient = createAuthorizedClient();
  const repository = new AccountRepository(httpClient);
  const userDataFromServer = await repository.getUserData();
  const requiredUserAttributes = getRequiredUserAttributes(
    userDataFromServer.UserAttributes
  );

  const paymentSubscription = await repository.isSubscriptionVerified();
  setUserData({
    ...previousUserData,
    ...requiredUserAttributes,
    'cognito:groups': userDataFromServer.Groups.map(
      ({ GroupName }) => GroupName
    ),
    // Implicitly, puts the user in the subscribed group if everything is ok
    is_subscription_verified: paymentSubscription.isSubscribed,
    is_payment_profile_created: paymentSubscription.isPaymentProfileCreated,
  });
};
